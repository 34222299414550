import { path } from 'ramda';

import {
   DeleteJob,
   GetSystemJobTemplates,
   GetWorkspace,
   TerminateJob,
   UpdateUserEntitlementData,
   UserEntitlementData,
} from '@/gql';
import { fetchPolicies } from '@/gql/constant';

import { throwWhenError } from '@/store/common/utils';
import { makeUpdateEntitlementInput } from '@/store/settings/utils';

import { errorTypes } from '@/utils/constants';

const getWorkspace = (client) =>
   client
      .query(GetWorkspace, {}, { requestPolicy: fetchPolicies.networkOnly })
      .toPromise()
      .then(throwWhenError(errorTypes.jobGetError))
      .then(path(['data', 'workspace']));

const deleteJobs = (client, jobIdList) => {
   return Promise.all(
      jobIdList.map((jobId) =>
         client
            .mutation(
               DeleteJob,
               { input: { id: jobId } },
               {
                  additionalTypenames: ['Workspace'],
               },
            )
            .toPromise(),
      ),
   );
};

const getEntitlementData = (client) =>
   client
      .query(
         UserEntitlementData,
         {},
         {
            requestPolicy: fetchPolicies.networkOnly,
         },
      )
      .toPromise()
      .then(throwWhenError(errorTypes.getEntitlementDataError))
      .then(path(['data', 'userEntitlementData']));

const updateEntitlementData = ({ client, entitlementDataId, pepTelemetryEnabled, telemetryEnabled }) => {
   const input = makeUpdateEntitlementInput(entitlementDataId, pepTelemetryEnabled, telemetryEnabled);
   return client.mutation(UpdateUserEntitlementData, { input }).toPromise();
};

const terminateJob = (client, jobId) => {
   return client
      .mutation(TerminateJob, { input: { id: jobId } })
      .toPromise()
      .then(throwWhenError(errorTypes.terminateJobError));
};

const getSystemJobTemplates = (client) =>
   client
      .query(GetSystemJobTemplates, {}, { requestPolicy: fetchPolicies.networkOnly })
      .toPromise()
      .then(throwWhenError('getSystemJobTemplates'))
      .then(path(['data', 'workspace', 'systemJobTemplateCatalog']));

export default {
   getWorkspace,
   deleteJobs,
   getEntitlementData,
   updateEntitlementData,
   terminateJob,
   getSystemJobTemplates,
};
