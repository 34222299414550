import { always, cond, T } from 'ramda';
import { useTranslation } from 'react-i18next';

import { colorStatusVariants, statusCollection } from '@/utils/constants';
import { within } from '@/utils/utils';

export const getStatusPillClasses = cond([
   [within(statusCollection.success), always(colorStatusVariants.success)],
   [within(statusCollection.warning), always(colorStatusVariants.warning)],
   [within(statusCollection.error), always(colorStatusVariants.error)],
   [T, always(colorStatusVariants.info)],
]);

export const StatusTag = ({ status, slot }) => {
   const { t } = useTranslation();

   return (
      <p slot={slot} className="flex items-center justify-center">
         <span className={`text-white ${getStatusPillClasses(status)} border border-solid rounded-2xl py-1 px-3`}>
            {t(`status.${status}`)}
         </span>
      </p>
   );
};
