// @ts-nocheck

/*eslint-disable max-lines*/
import {
   AbortMultipartUploadCommand,
   CompleteMultipartUploadCommand,
   CreateMultipartUploadCommand,
   GetObjectCommand,
   S3Client,
   UploadPartCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { FetchHttpHandler } from '@smithy/fetch-http-handler';
import { compose, equals, find, mergeLeft, path, propEq, tap, when } from 'ramda';

import { IssueFrsFileRepositoryDownloadTickets, IssueFrsFileRepositoryUploadTickets } from '@/gql';

import { store as fileStore } from '@/store/files/store';
import { errors } from '@/store/listing/errors';
import { store } from '@/store/listing/state';

import { getTaskId } from '@/utils/accessors';
import { hasGQLError, isNotNilOrEmpty, isUploading } from '@/utils/comparators';
import { errorTypes } from '@/utils/constants';
import { CustomError, GQLError } from '@/utils/customErrors';
import { sendEmbeddedMessage } from '@/utils/embeddedMessage';

import { computeChecksum, computeFilePartsData, computeLoadedValue, retryWithBackoff } from './fileUtils';

const REQUEST_TIMEOUT = 600000; // 10 minutes
const MAX_ATTEMPTS = 5;
const CHUNK_SIZE = 10;

const sendTicketMessage = tap((tickets) => sendEmbeddedMessage('fileTransfer/newTickets', { tickets }));

export const getDownloadTicket = (client, repositoryId) =>
   client
      .mutation(IssueFrsFileRepositoryDownloadTickets, { input: { repositoryId } })
      .toPromise()
      .then(path(['data', 'issueFrsFileRepositoryDownloadTickets']))
      .then(sendTicketMessage);

const uploadFilesTicket = (client, repositoryId) =>
   client
      .mutation(IssueFrsFileRepositoryUploadTickets, { input: { repositoryId } })
      .toPromise()
      .then(
         when(hasGQLError, () => {
            throw new Error('Request for upload ticket failed');
         }),
      )
      .then(path(['data', 'issueFrsFileRepositoryUploadTickets']))
      .catch((err) => {
         throw new GQLError(errorTypes.uploadFilesError, err);
      });

const credentialsFromTicket = ({ accessKey, secretAccessKey, sessionToken, expiration }) => ({
   accessKeyId: accessKey,
   secretAccessKey,
   sessionToken,
   expiration: new Date(expiration),
});

/**
 * Returns a callback function that will call the graphQL query to set new credentials as needed
 * for the S3 client
 */
const getCredentials = (client, repositoryId) => () =>
   uploadFilesTicket(client, repositoryId).then(credentialsFromTicket);

function createS3Client(ticket, onGetCredentials) {
   const customHandler = new FetchHttpHandler({
      requestTimeout: REQUEST_TIMEOUT,
      keepAlive: false,
   });

   return new S3Client({
      endpoint: ticket.serviceUrl,
      region: ticket.bucketRegion,
      credentials: onGetCredentials ?? credentialsFromTicket(ticket),
      maxAttempts: MAX_ATTEMPTS,
      requestHandler: customHandler,
      signatureVersion: 'v4',
   });
}

export const getClientAndSignedUrl = (filename) => (ticket) => {
   const s3 = createS3Client(ticket);
   const key = `${ticket.prefix}${filename}`;
   const command = new GetObjectCommand({ Bucket: ticket.bucket, Key: key });
   return getSignedUrl(s3, command);
};

const byTaskId = (id) => compose(equals(id), getTaskId);
const getJobByTaskId = (id) => store.state().workspace.jobs.find(byTaskId(id));
const isJobUploading = compose(isUploading, getJobByTaskId);

const progressCallback = (fileData, fileId, taskId, startTime, progressCounter) => {
   const total = fileData.fileSize;
   const loaded = computeLoadedValue(fileData, progressCounter[fileId]);
   const elapsedSeconds = Math.round((Date.now() - startTime) / 1000);
   fileStore.actions.updateUploadProgress({ loaded, total, elapsedSeconds }, taskId, fileId);
   progressCounter[fileId]++;
};

function uploadPartCmd(uploadPartParameters, checksum, buffer) {
   const { partNumber, fileId } = uploadPartParameters.fileParameters;
   const { taskId, abortController, uploadId } = uploadPartParameters.controlParameters;
   const { s3, bucket, key } = uploadPartParameters.s3Parameters;

   const uploadPartCommand = new UploadPartCommand({
      Bucket: bucket,
      Key: key,
      PartNumber: partNumber,
      UploadId: uploadId,
      Body: buffer,
      ChecksumSHA256: checksum,
   });

   return s3
      .send(uploadPartCommand, { abortSignal: abortController.signal })
      .then((uploadResponse) => mergeLeft({ checksum }, { uploadResponse }))
      .catch((e) => {
         fileStore.actions.pauseUpload(taskId, fileId);
         throw e;
      });
}

const createMultipartUploadWithRetry = (s3, createUploadParams) => {
   const createUploadCommand = new CreateMultipartUploadCommand(createUploadParams);
   return retryWithBackoff(() => s3.send(createUploadCommand));
};

const completeMultipartUploadWithRetry = (s3, completeParams) => {
   const completeCommand = new CompleteMultipartUploadCommand(completeParams);
   return retryWithBackoff(() => s3.send(completeCommand));
};

const uploadPart = (uploadPartParameters) => {
   const { file, fileData, partNumber, fileId } = uploadPartParameters.fileParameters;
   const { startTime, taskId, progressCounter, abortController } = uploadPartParameters.controlParameters;

   const start = (partNumber - 1) * fileData.filePartSize;
   const end = Math.min(start + fileData.filePartSize, fileData.fileSize);
   const partData = file.slice(start, end);
   return partData
      .arrayBuffer()
      .then((buffer) => computeChecksum(buffer).then((checksum) => ({ checksum, buffer })))
      .then(({ checksum, buffer }) => retryWithBackoff(() => uploadPartCmd(uploadPartParameters, checksum, buffer)))
      .then(({ checksum, uploadResponse }) => {
         // Track the progress of each part upload except for config.json
         if (fileData.fileName !== '.config.json') {
            progressCallback(fileData, fileId, taskId, startTime, progressCounter);
         }
         if (!isJobUploading(taskId)) {
            abortController.abort();
         }
         return { ETag: uploadResponse.ETag, PartNumber: partNumber, ChecksumSHA256: checksum };
      });
};

function runPromisesInChunks(promises, chunkSize, fileName, i = 0, uploadedParts = []) {
   // Check if all promises have been executed
   if (i >= promises.length) {
      return Promise.resolve(uploadedParts);
   }
   const chunk = promises.slice(i, i + chunkSize);
   const chunkPromises = chunk.map((promise) => promise());

   // Wait for all promises in the chunk to either fulfill or reject
   return Promise.allSettled(chunkPromises).then((chunkResults) => {
      // Check if any promise in the chunk was rejected
      const rejectedPromises = chunkResults.filter((result) => result.status === 'rejected');

      if (rejectedPromises.length > 0) {
         // Handle rejection of promises in the chunk
         const rejectedPromisesErrors = rejectedPromises.map((result) => result.reason);

         // if at least 1 reason is AbortError, stop the upload
         if (isNotNilOrEmpty(find(propEq(errorTypes.abortError, 'name'), rejectedPromisesErrors))) {
            throw new CustomError(errorTypes.abortError, `Upload interrupted by user.`);
         } else {
            throw new CustomError(
               errorTypes.uploadFilesError,
               `Upload interrupted unexpectedly for file ${fileName}: ${rejectedPromisesErrors[0].message}`,
            );
         }
      }

      // Collect the results from the fulfilled promises in the chunk
      const fulfilledResults = chunkResults
         .filter((result) => result.status === 'fulfilled')
         .map((result) => result.value);

      uploadedParts.push(...fulfilledResults);

      // Recursively call the function for the next chunk
      return runPromisesInChunks(promises, chunkSize, fileName, i + chunkSize, uploadedParts);
   });
}

function uploadFileToS3({ s3, bucket, keyPrefix }, taskId, { progressCounter, abortController }, file, fileId) {
   const fileData = computeFilePartsData(file);
   const key = `${keyPrefix}${fileData.fileName}`;
   const startTime = Date.now();
   let uploadId = null;

   const createUploadParams = { Bucket: bucket, Key: key, ChecksumAlgorithm: 'SHA256' };
   return createMultipartUploadWithRetry(s3, createUploadParams)
      .then((createUploadResponse) => {
         uploadId = createUploadResponse.UploadId;

         /**
          * Create an array of chunks of the file based on the maxPartSize (5Mb)
          * and the max number of files we want to treat in parallel (say 10)
          * Then loop around each chunk and upload each part of a chunk in parallel
          * before moving on to the next chunk
          */
         const uploadPromises = [];
         for (let partNumber = 1; partNumber <= fileData.fileNumParts; partNumber++) {
            const uploadPartParameters = {
               s3Parameters: { s3, bucket, key },
               fileParameters: { file, fileData, partNumber, fileId },
               controlParameters: { startTime, taskId, uploadId, progressCounter, abortController },
            };

            uploadPromises.push(() => uploadPart(uploadPartParameters));
         }

         return runPromisesInChunks(uploadPromises, CHUNK_SIZE, fileData.fileName);
      })
      .then((uploadedParts) => {
         // Sort the uploaded parts array based on part number
         uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber);

         // Complete the multipart upload
         const completeParams = {
            Bucket: bucket,
            Key: key,
            UploadId: uploadId,
            MultipartUpload: { Parts: uploadedParts },
         };
         return completeMultipartUploadWithRetry(s3, completeParams);
      })
      .catch((e) => {
         handleUploadError(e, taskId, fileId, uploadId, { s3, bucket, key });
      });
}

function handleUploadError(e, taskId, fileId, uploadId, s3Params) {
   const errorType = e.type;
   if (errorType === errorTypes.abortError) {
      // Case User manual abort:
      // Set step to finished
      fileStore.actions.abortUpload(taskId, fileId);
      // Send Abort request to S3
      abortUpload(uploadId, s3Params);
   } else {
      // Case Network error:
      // Set Progress bar to failed
      fileStore.actions.setUploadError(taskId, fileId, 'Upload failed');
      // Store error mesage for Toast
      errors.updateErrorsState(e);
      // Send Abort request to S3 (if server side network error)
      abortUpload(uploadId, s3Params);
      // Throw exception to Terminate Job process
      throw e;
   }
}

/**
 * Send AbortMultipartUploadCommand to S3 to delete properly already uploaded parts
 */
function abortUpload(uploadId, { s3, bucket, key }) {
   const abortParams = { Bucket: bucket, Key: key, UploadId: uploadId };
   const abortCommand = new AbortMultipartUploadCommand(abortParams);
   return s3.send(abortCommand);
}

const createConfig = (taskId) => {
   const infos = fileStore.state();
   const { sim, macro } = infos[taskId];

   const jsonData = {
      sim,
      macro,
   };

   return new File([JSON.stringify(jsonData)], '.config.json');
};

export const uploadFiles = (client, repositoryId, files, taskId, form) => {
   const filesToUpload = form.otherFiles.length > 0 ? [...files, createConfig(taskId)] : files;
   const onGetCredentials = getCredentials(client, repositoryId);
   const abortController = new AbortController();
   const progressCounter = Array(filesToUpload.length).fill(1);

   return uploadFilesTicket(client, repositoryId)
      .then((ticket) => {
         const s3 = createS3Client(ticket, onGetCredentials);
         const bucket = ticket.bucket;
         const keyPrefix = ticket.prefix;

         return { s3, bucket, keyPrefix };
      })
      .then((uploadData) =>
         Promise.all(
            filesToUpload.map((file, i) =>
               uploadFileToS3(uploadData, taskId, { progressCounter, abortController }, file, i),
            ),
         ),
      );
};
