import { useTranslation } from 'react-i18next';

import { login } from '@/store/auth/auth';

import { SwwcButton } from '@/swwc';

export const Landing = () => {
   const { t } = useTranslation();
   // Not a colour in the UX guidelines, but matches the colour of the actual sign in page
   return (
      <div className="bg-[#000028] h-screen flex flex-col justify-center items-center gap-4">
         <p className="text-white">{t('common.landing')}</p>
         {/**
          * Step 1 of authentication.
          * In a real auth flow, this button would redirect to the OAuth 2.0 authorisation endpoint
          * with url parameters for client id, redirect uri, response type (=code) scope(=openid).
          * See `login` function in auth.js and https://openid.net/specs/openid-connect-basic-1_0.html#AuthenticationRequest
          */}
         <SwwcButton label="Sign In" variant="accent-high-contrast" size="md" onClick={login} />
         {/* <p className='text-white text-sm'>
         Check the flow when fetching the token fails for some reason
      </p>
      <SwwcButton
         label='Sign In (Fail)'
         variant='accent-negative'
         size='md' onClick={() => navigate('/callback')} />
      */}
      </div>
   );
};
