// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { jobTypesStore } from '@/store/jobtypes/store';
import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { QuickActions } from '@/modules/listing/components/QuickActions';

import { hasID, isStatusDeleting, isStatusTerminating } from '@/utils/comparators';
import { accessors } from '@/utils/constants';
import { goToDetails } from '@/utils/jobUtils';

import { SwwcCheckbox } from '@/swwc';

import { TableCells } from './TableCells';

export const TableBody = ({ jobs, selectedJobs }) => {
   const navigate = useNavigate();
   const { t } = useTranslation();
   const { mappings } = useStream(jobTypesStore.state);

   return (
      <tbody data-cy="jobTable">
         {jobs.map((job) => (
            <tr key={crypto.randomUUID()} onClick={() => goToDetails(navigate)(job)}>
               <td onClick={(e) => e.stopPropagation()}>
                  <div className="flex justify-center items-center">
                     <SwwcCheckbox
                        onCheckChanged={() => store.actions.selectJob(job)}
                        checked={hasID(job.id)(selectedJobs)}
                        id={job.id}
                        disabled={isStatusDeleting(job)}
                     />
                  </div>
               </td>
               {accessors.map(TableCells(job, t, mappings))}
               <td onClick={(e) => e.stopPropagation()} data-accessor="quickActions">
                  {isStatusDeleting(job) || isStatusTerminating(job) ? (
                     <LoadingIndicator size="xsmall" />
                  ) : (
                     <QuickActions job={job} />
                  )}
               </td>
            </tr>
         ))}
      </tbody>
   );
};
