import { compose, prop } from 'ramda';

import { store } from '@/store/listing/state';

export const getFileKey = (filename, repositoryId) => filename + repositoryId;

const getFileDownloadInfo = (file, repositoryId) => store.state().downloadInfo[getFileKey(file.filename, repositoryId)];

export const getDownloadStatus = compose(prop('status'), getFileDownloadInfo);

export const getDownloadProgress = compose(prop('progress'), getFileDownloadInfo);
