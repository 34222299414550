// @ts-nocheck
import logoUrl from '/img/simcenter-x-logo.png';
import { compose, prop, propEq, when } from 'ramda';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useClient } from 'urql';

import { login, tokenIsInDate } from '@/store/auth/auth';
import { authStore } from '@/store/auth/store';
import { creditStore } from '@/store/credits/state';
import { featureFlagsStore } from '@/store/featureFlags/state';
import { jobTypesStore } from '@/store/jobtypes/store';
import state, { store } from '@/store/listing/state';
import { store as settingsStore } from '@/store/settings/store';
import { useStream } from '@/store/useStream';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { UserNavigationPanel } from '@/modules/common/SidebarPanels';
import { ToastHandler } from '@/modules/common/ToastHandler';
import { Access } from '@/modules/common/access/Access';
import { ActionsJobModal } from '@/modules/listing/components/ActionsJobModal';

import { dataStates, errorTypes, productBrand } from '@/utils/constants';
import { addEmbeddedMessageHandler, isEmbedded, sendEmbeddedMessage } from '@/utils/embeddedMessage';
import { rejectByType } from '@/utils/filters';

import { SwwcApplicationFrame, SwwcCommandButton, SwwcPrimaryNavigationBar, SwwcPrimaryNavigationPanel } from '@/swwc';

import { initAnalytics } from '@/analytics/init';
import { initRefiner } from '@/analytics/refiner';
import { initWalkme, openWalkme } from '@/walkme';

export const Layout = () => {
   const navigate = useNavigate();
   const client = useClient();
   const { errors } = useStream(state.app.state);
   const errorsFiltered = rejectByType(errorTypes.jobGetError)(errors);
   const auth = useStream(authStore.state);
   const { userEntitlementData } = useStream(settingsStore.state);

   useEffect(() => {
      if (tokenIsInDate(auth)) {
         store.actions.getWarnInfo();
         store.actions.loadJobs(client, true);
         jobTypesStore.actions.getSystemJobTemplates(client);
         creditStore.actions.loadCredits(client, true);
         settingsStore.actions.getEntitlement(client);
         settingsStore.actions.initFromStorage(localStorage);
         featureFlagsStore.actions.loadFeatureFlags(client);
         settingsStore.actions.setWMCBranch(window.location.search);
         initWalkme(auth);
      }
   }, [auth?.appServerUrl]);

   useEffect(() => {
      if (userEntitlementData.dataState === dataStates.ready) {
         initAnalytics();
         initRefiner();
      }
   }, [userEntitlementData.dataState]);

   // embedded handler for redirect to new job
   useEffect(() => {
      if (isEmbedded) {
         addEmbeddedMessageHandler(
            'redirectTo',
            when(
               prop('detail'),
               compose(
                  when(propEq('newjob', 'path'), () => {
                     navigate('/hpcJob');
                     sendEmbeddedMessage('populateSimFileField', { cssSelector: "input[name='simulationFile']" });
                  }),
                  JSON.parse,
                  prop('detail'),
               ),
            ),
         );
      }
   }, []);

   if (!tokenIsInDate(auth)) {
      login();
      return <LoadingIndicator className="h-screen flex justify-center items-center" />;
   }

   return (
      <SwwcApplicationFrame app-name={productBrand}>
         <SwwcPrimaryNavigationBar slot="primary-navigation" hideCommandItemsLabel hideAccountIcon>
            <img src={logoUrl} slot="edge-icon-top" width="32" alt="Simcenter X" />
            <SwwcCommandButton
               slot="top-section--command-items"
               onClick={() => navigate('/')}
               id="simx-nav-home"
               icon="cmdHome24"
               iconSize="lg"
               orientation="vertical"
            />
            <div slot="bottom-section--command-items" className="w-full mb-4">
               <SwwcCommandButton
                  icon="cmdSettings24"
                  onClick={() => navigate('/settings')}
                  id="simx-nav-settings"
                  iconSize="lg"
                  orientation="vertical"
               />
               <SwwcCommandButton
                  icon="cmdHelp24"
                  onClick={openWalkme}
                  id="simx-nav-help"
                  iconSize="lg"
                  orientation="vertical"
               />
            </div>
            <div slot="bottom-section--command-items" id="user-logout-avatar" className="w-full mb-[16px]">
               <SwwcCommandButton icon="cmdUser24" iconSize="md" id="simx-nav-user" orientation="vertical" />
               <div className="rounded bg-white"></div>
            </div>
            <SwwcPrimaryNavigationPanel
               slot="navigation-panel"
               panelTitle={null}
               triggerCommandButtonToggleId="simx-nav-user"
               closeOnClickOutside
               alignBottom
               style={{ '--swwc-primary-navigation-panel--width': '280px' }}
            >
               <UserNavigationPanel />
            </SwwcPrimaryNavigationPanel>
         </SwwcPrimaryNavigationBar>
         <main slot="content" className="relative overflow-x-hidden h-full">
            <Access />
            <ToastHandler item={errorsFiltered[0]} />
            <ActionsJobModal />
            <Outlet />
         </main>
      </SwwcApplicationFrame>
   );
};
