import { dataStatePath, dataStates } from '@/utils/constants';

import services from './services';

export const rawActions = (present) => ({
   loadCredits: (client, poll = false) => {
      present({ op: 'replace', value: dataStates.loading, path: dataStatePath });

      return services
         .getUserBalance(client)
         .then((credits) => {
            present({ credits, dataState: dataStates.ready }, { client, poll });
         })
         .catch(() => {
            present({ op: 'replace', value: dataStates.error, path: dataStatePath }, { client, poll });
         });
   },
   test: {
      initCreditState: (data) => present({ op: 'replace', value: data, path: '' }),
   },
});
