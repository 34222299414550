import styles from '@/modules/common/table.module.css';

import { TableBody } from './TableBody';
import { TableHeaders } from './TableHeaders';

export const JobTable = ({ jobs, selectedJobs }) => {
   return (
      <div className="w-full lg:flex lg:justify-center overflow-x-auto sm:overflow-visible">
         <table className={styles.table}>
            <TableHeaders nSelectedJobs={selectedJobs.length} />
            <TableBody jobs={jobs} selectedJobs={selectedJobs} />
         </table>
      </div>
   );
};
