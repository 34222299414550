import { isNotNil, mergeDeepLeft } from 'ramda';

import { createApp } from '@/store/common/pal';
import { jobTypesStore } from '@/store/jobtypes/store';

import { actions } from './actions';
import { model } from './model';
import {
   allowSubmission,
   handlePrecisionUpdate,
   trimExtension,
   updateJobNameRequired,
   updatePrecisionRequired,
} from './utils';

const nap = ({ proposedState, currentState }) => {
   //Update the job name if a correct simFile has been updated while the name is empty
   if (updateJobNameRequired(currentState, proposedState)) {
      return mergeDeepLeft({ name: trimExtension(proposedState.simulationFile) }, proposedState);
   }

   // Check if precision is supported by selected version, if not switch selected precision
   if (updatePrecisionRequired(proposedState)) {
      const { applications } = jobTypesStore.state();
      const updatedState = handlePrecisionUpdate(applications, proposedState);

      if (isNotNil(updatedState)) {
         return updatedState;
      }
   }

   return mergeDeepLeft({ canSubmit: allowSubmission(proposedState) }, proposedState);
};

export const hpcSubmissionStore = createApp(actions, model, nap);
