/* eslint-disable camelcase*/
import { path } from 'ramda';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { createLogoutUrl } from '@/store/auth/auth';
import { authStore } from '@/store/auth/store';
import { useStream } from '@/store/useStream';

import { SwwcIcon, SwwcLink } from '@/swwc';

const regions = {
   'us-east-1': 'United States (N. Virginia)',
   'eu-central-1': 'Europe (Stockholm)',
   'ap-northeast-1': 'Asia Pacific (Tokyo)',
};

const getUserInfo = (token) => {
   const ecaId = path(['tenant', 'ecaId'], token);
   const region = path(['sws.samauth.tiers', '0', 'region'], token);
   return { ecaId, region: regions[region] };
};

export const UserNavigationPanel = () => {
   const formRef = useRef(null);
   const {
      idTokenData: { email, family_name, given_name },
      accessTokenData,
      accessToken,
   } = useStream(authStore.state);
   const { t } = useTranslation();

   const handleLogout = () => {
      formRef.current.submit();
      authStore.actions.clearAuthData(localStorage, false);
   };

   const userInfo = getUserInfo(accessTokenData);

   return (
      <div className="overflow-hidden">
         <div className="flex flex-col items-center gap-2 mb-8">
            <figure className="grid place-items-center bg-white rounded-full w-[120px] h-[120px] overflow-hidden mb-2">
               <SwwcIcon name="cmdUser24" size="lg" />
            </figure>
            <span className="text-4xl">
               {given_name} {family_name}
            </span>
            <span>{email}</span>
         </div>
         <ul className="mb-8 flex flex-col gap-4">
            <li className="flex flex-col">
               <span className="font-bold">{t('labels.ecaID')}:</span> <span>{userInfo.ecaId}</span>
            </li>
            <li className="flex flex-col">
               <span className="font-bold">{t('labels.region')}:</span> <span>{userInfo.region}</span>
            </li>
         </ul>
         <form method="POST" action={createLogoutUrl()} ref={formRef}>
            <input type="hidden" name="accessToken" value={accessToken} />
            <div className="flex gap-1 items-center">
               <SwwcIcon name="cmdSignout16" size="md" style={{ '--swwc-icon--color': '#FFFFFF' }} />
               <SwwcLink
                  text={t('commands.logOut')}
                  onClick={handleLogout}
                  style={{ '--swwc-link--color--normal--default--light': '#FFFFFF' }}
               />
            </div>
         </form>
      </div>
   );
};
