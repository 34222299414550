// @ts-nocheck
import { path, pick, when } from 'ramda';

import { ComputeTaskFileRepositories, CreateJob, Job, SubmitJob } from '@/gql';
import { fetchPolicies } from '@/gql/constant';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { hasGQLError } from '@/utils/comparators';
import { applications, errorTypes, jobTypeKeys } from '@/utils/constants';

import { pollWithCancel, throwWhenError } from './common/utils';

const getJobDetails = (client, id) =>
   client
      .query(Job, { id })
      .toPromise()
      .then(throwWhenError(errorTypes.jobGetError))
      .then(path(['data', 'job']));

const createJob = (client, input) =>
   client
      .mutation(CreateJob, { input })
      .toPromise()
      .then(throwWhenError('createJob'))
      .then(path(['data', 'createJob', 'job']));

const createJobAndInput = (client, form, applicationTemplates, idempotencyToken) => {
   // Find the application template corresponding to the form
   const app = getTemplateByMarketingVersion(form.version.selected)(applicationTemplates);

   const application = {
      type: app.type,
      version: app.version,
      precision: form.precision.selected,
      typeHint: app.typeHint,
   };

   const taskInput = {
      name: form.simulationFile.name,
      application: applications.StarCCMp,
      cmdOptions: [
         '-power',
         '-graphics',
         'mesa_swr',
         '-rgraphics',
         'mesa_swr',
         '-mpi',
         'openmpi',
         '-batch',
         form.macro?.name ?? 'run',
         form.simulationFile.name,
      ],
      launch: true,
   };

   return createJob(client, {
      name: form.name,
      jobDefinition: {
         jobType: jobTypeKeys.hpc,
         applications: [application],
         computeType: pick(['name', 'typeHint'], form.submissionTemplate),
      },
      tasks: taskInput,
      idempotencyToken,
   });
};

const submitJob = (client, id) =>
   client
      .mutation(SubmitJob, { input: { id } })
      .toPromise()
      .then(
         when(hasGQLError, (res) => {
            const error = path(['error', 'graphQLErrors', '0', 'extensions', 'serviceError'], res);
            throw new Error('Submission failed', { cause: { type: error.code, parameters: error.parameters } });
         }),
      )
      .then(path(['data', 'submitJob', 'job']));

const computeTaskFileRepositories = (client, computeTaskId, validate) => {
   const fn = () =>
      client
         .query(
            ComputeTaskFileRepositories,
            { computeTaskId },
            {
               requestPolicy: fetchPolicies.networkOnly,
            },
         )
         .toPromise();
   return pollWithCancel({ fn, validate });
};

export default {
   computeTaskFileRepositories,
   submitJob,
   createJobAndInput,
   createJob,
   getJobDetails,
};
