import { __, always, assoc, compose, endsWith, evolve, find, ifElse, isNotNil, map, prop } from 'ramda';

import { isNotNilOrEmpty } from '@/utils/comparators';
import { fileExtensions, fileStatus } from '@/utils/constants';
import { formatBytes, formatSpeed, formatTime, getUploadInfoUpdate } from '@/utils/formatters';

const buildFile = compose(assoc('name', __, { upload: { progress: 0, status: fileStatus.progressing } }), prop('name'));
const buildFileInfo = ifElse(isNotNil, buildFile, always(null));
const getOtherFilesInfoFromForm = compose(map(buildFileInfo), prop('otherFiles'));

export const getSimulationFile = (form, config, taskName) => {
   if (form) {
      return buildFileInfo(form.simulationFile);
   }

   if (config?.sim) {
      return { name: config.sim };
   }

   return { name: taskName };
};

export const getJavaMacroFile = (form, config, inputFiles) => {
   if (form) {
      return buildFileInfo(form.macro);
   }

   if (config) {
      return { name: config.macro };
   }
   const name = compose(prop('filename'), find(compose(endsWith(fileExtensions.macro), prop('filename'))))(inputFiles);
   return { name };
};

export const getOtherFiles = (form, config, inputFiles) => {
   if (form) {
      return getOtherFilesInfoFromForm(form);
   }

   if (isNotNilOrEmpty(config)) {
      return inputFiles.reduce((acc, file) => {
         if (file.filename !== config.sim && file.filename !== config.macro && file.filename !== '.config.json') {
            acc.push({ name: file.filename });
         }
         return acc;
      }, []);
   }

   return [];
};

export const transformUploadInfo = compose(
   evolve({
      loaded: formatBytes,
      total: formatBytes,
      rate: formatSpeed,
      remainingTime: formatTime,
   }),
   getUploadInfoUpdate,
);
