// @ts-ignore
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { authStore } from '@/store/auth/store';
import { getNiceDCVUrlFromState } from '@/store/auth/utils';
import { useStream } from '@/store/useStream';

import { productBrand } from '@/utils/constants';

export function Session() {
   const { sessionId } = useParams();
   const { accessToken } = useStream(authStore.state);

   const gateway = getNiceDCVUrlFromState();

   useEffect(() => {
      document.title = `${productBrand}: ${window.simcenterXJobName || 'Remote Desktop session'}`;
   }, []);

   return (
      <div className="h-[100vh]">
         <iframe
            id="remoteDesktopSessionFrame"
            title="Remote Desktop Session"
            width="100%"
            height="100%"
            allowFullScreen
            src={`${gateway}:8443?authToken=${encodeURIComponent(accessToken)}#${encodeURIComponent(sessionId)}`}
         />
      </div>
   );
}
