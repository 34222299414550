import { adjust, evolve, mergeDeepLeft } from 'ramda';

import { getJavaMacroFile, getOtherFiles, getSimulationFile, transformUploadInfo } from './utils';

export const actions = (present) => {
   return {
      setJobFiles: ({ config, inputFiles, taskName, taskId, form }) => {
         const simulationFile = getSimulationFile(form, config, taskName);
         const javaMacro = getJavaMacroFile(form, config, inputFiles);
         const otherFiles = getOtherFiles(form, config, inputFiles);

         const value = {
            sim: simulationFile.name,
         };

         const files = [simulationFile];

         if (javaMacro?.name) {
            value.macro = javaMacro.name;
            files.push(javaMacro);
         }

         files.push(...otherFiles);
         value.files = files;

         present({ op: 'add', value, path: `/${taskId}` });
      },

      updateUploadProgress: (uploadInfo, taskId, index) => {
         const path = `/${taskId}/files/${index}/upload`;
         const value = transformUploadInfo(uploadInfo);

         present({ op: 'replace', path, value });
      },

      abortUpload: (taskId, index) => {
         present(
            evolve({
               [taskId]: { files: adjust(index, mergeDeepLeft({ upload: { status: 'terminated' } })) },
            }),
         );
      },

      pauseUpload: (taskId, index) => {
         present(
            evolve({
               [taskId]: { files: adjust(index, mergeDeepLeft({ upload: { status: 'paused' } })) },
            }),
         );
      },

      setUploadError: (taskId, index, message) => {
         present(
            evolve({
               [taskId]: {
                  files: adjust(index, mergeDeepLeft({ upload: { status: 'error', errorMessage: message } })),
               },
            }),
         );
      },

      clear: (taskId) => present(mergeDeepLeft({ [taskId]: undefined })),
   };
};
