import { propEq, when } from 'ramda';

import { store } from '@/store/listing/state';

import { hasGQLError, isInsufficientError } from '@/utils/comparators';
import { computeTaskUnifiedStatus, dataStatePath, dataStates } from '@/utils/constants';
import { GQLError } from '@/utils/customErrors';

export const whenPollRequired = when(propEq(true, 'poll'));

export function pollWithCancel({ fn, validate, baseInterval = 5000, delayIncrease = 1000, maxAttempts = 100 }) {
   let attempts = 0;
   let timer;
   function execute(resolve, reject) {
      fn().then((res) => {
         attempts += 1;
         if (validate(res)) {
            resolve(res);
         } else if (maxAttempts === attempts) {
            reject('Timed out');
         } else {
            timer = setTimeout(execute, baseInterval + attempts * delayIncrease, resolve, reject);
         }
      });
   }
   function clear() {
      clearTimeout(timer);
   }
   return { promise: new Promise(execute), cancel: clear };
}

export const throwWhenError = (errorType) =>
   when(hasGQLError, (res) => {
      throw new GQLError(errorType, res.error);
   });

export const handleSubmitError = (present) => (id, jobType) => (err) => {
   err.cause.jobType = jobType;

   if (isInsufficientError(err.cause)) {
      store.actions.setJobStatus(id, computeTaskUnifiedStatus.InsufficientCredits);
   } else {
      store.actions.setJobStatus(id, computeTaskUnifiedStatus.Failed);
   }

   // Only display the modal if you are in the details page
   if (window.location.pathname.includes(id)) {
      present([
         { op: 'replace', value: dataStates.error, path: dataStatePath },
         { op: 'replace', value: err.cause, path: '/error' },
      ]);
   }
};
