import { NavigationButton } from './components/NavigationButton';
import { Pages } from './components/Pages';

export const Pagination = ({ page, totalPages, onPageChange }) => (
   <ul className="flex gap-2">
      <li key="back-pagination-button">
         <NavigationButton disabled={page === 1} direction="left" onClick={() => onPageChange(page - 1)} />
      </li>
      <Pages page={page} totalPages={totalPages} onPageChange={onPageChange} />
      <li key="forward-pagination-button">
         <NavigationButton disabled={page === totalPages} direction="right" onClick={() => onPageChange(page + 1)} />
      </li>
   </ul>
);
