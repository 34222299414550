import { gql } from '@urql/core';

export default gql`
   query ComputeTaskFileRepositories($computeTaskId: ID!) {
      node(id: $computeTaskId) {
         id
         ... on ComputeTask {
            inputFileRepository {
               id
               status
               ... on FrsFileRepository {
                  files {
                     filename
                     size
                  }
               }
            }
            outputFileRepository {
               id
               status
               ... on FrsFileRepository {
                  files {
                     filename
                     size
                  }
               }
            }
         }
      }
   }
`;
