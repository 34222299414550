import { createApp } from '@/store/common/pal';
import { createStateStream } from '@/store/common/state';

import { dataStates } from '@/utils/constants';

import { rawActions } from './actions';
import { nap } from './nap';

const appState = createStateStream({
   errors: [],
});

export const model = {
   dataState: dataStates.ready,
   workspace: {
      jobs: [],
   },
   sorting: {
      accessor: {
         // Put the most recent jobs first by default
         label: 'submitted',
         path: ['submissionTime'],
         type: 'date',
      },
      direction: 'descending',
   },
   page: 1,
   selection: [],
   displayedJobs: [],
   totalPages: 1,
   totalDisplayedJobs: 0,
   totalExistingJobs: 0,
   filterOptions: [],
   runningJobs: 0,
   warnUserRemoteSession: { runningSessions: false, userWantsToBeWarned: true },
   warnUserHPCJob: false,
   // embedded mode stuff
   downloadInfo: {},
   overallDownloadInfo: {},
   downloadPath: {},
};

export default {
   app: appState,
};

export const store = createApp(rawActions, model, nap);
