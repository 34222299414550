import { whenPollRequired } from '@/store/common/utils';

import { pollDelay } from '@/utils/constants';
import { delay } from '@/utils/utils';

export const nap = ({ actions, context, proposedState }) => {
   const getCredits = () => {
      delay(pollDelay).then(() => actions.loadCredits(context.client, true));
   };

   whenPollRequired(getCredits, context);

   return proposedState;
};
