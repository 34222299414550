import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import { WarnHPCPreparing, WarnSessionIsInProcess } from '@/modules/common/WarnUser';
import { Credits } from '@/modules/credits/Credits';
import { CommandBar } from '@/modules/listing/components/CommandBar';
import { JobTable } from '@/modules/listing/components/jobTable/JobTable';

import { PAGINATION_THRESHOLD } from '@/utils/constants';

import { Pagination } from './components/pagination/Pagination';
import { Summary } from './components/summary/Summary';

export const JobManager = () => {
   const { selection, totalPages, page, totalDisplayedJobs, displayedJobs } = useStream(store.state);

   return (
      <>
         <WarnHPCPreparing className="flex flex-col" />
         <WarnSessionIsInProcess className="flex flex-col" />

         <div className="flex justify-between items-center">
            <CommandBar selection={selection} />
            <Credits />
         </div>
         <JobTable jobs={displayedJobs} selectedJobs={selection} />
         {totalPages > 1 && (
            <div className="flex justify-between items-center">
               <Summary page={page} totalEntries={totalDisplayedJobs} entriesPerPage={PAGINATION_THRESHOLD} />
               <Pagination page={page} totalPages={totalPages} onPageChange={store.actions.setPage} />
            </div>
         )}
      </>
   );
};
