import { setMessage } from './errors';

class GQLError extends Error {
   constructor(type, ...params) {
      super(...params);

      this.type = type;
      this.message = setMessage(params[0].networkError);
   }
}

class CustomError extends Error {
   constructor(type, message) {
      super(message);
      this.name = type;
      this.type = type;
   }
}
export { GQLError, CustomError };
