import { AwsRum } from 'aws-rum-web';
import { isNil, keys, pickBy } from 'ramda';

import { requiredEnvVars, rumErrors, rumTelemetries } from './constants';

/**
 * Retrieves the required environment variables for initializing the AWS CloudWatch RUM web client.
 * @returns {object} An object containing the required environment variable values.
 * @throws {Error} If any of the required environment variables are missing.
 */
const checkRequiredEnvVars = () => {
   const missingEnvVars = keys(pickBy(isNil, requiredEnvVars));
   if (missingEnvVars.length > 0) {
      throw new Error(`${rumErrors.missingVars}: ${missingEnvVars.join(', ')}`);
   }

   return requiredEnvVars;
};

/**
 * Initializes the AWS CloudWatch RUM web client.
 * @returns {AwsRum|null} The initialized AwsRum instance, or null if an error occurs.
 */
export const initializeAwsRum = () => {
   if (import.meta.env.PUBLIC_RUM_ENABLED === 'true') {
      console.log('Enabling RUM');
      try {
         const { rumIdentityPoolId, rumEndpoint, rumAppId, rumAppVersion, rumAppRegion } = checkRequiredEnvVars();

         // AWS CloudWatch RUM Configuration
         const config = {
            sessionSampleRate: 1,
            identityPoolId: rumIdentityPoolId,
            endpoint: rumEndpoint,
            telemetries: rumTelemetries,
            allowCookies: false,
            enableXRay: false,
         };

         return new AwsRum(rumAppId, rumAppVersion, rumAppRegion, config);
      } catch (error) {
         console.warn(rumErrors.errorInitilize, error);
         return null;
      }
   }
   return null;
};
