import { stream } from 'flyd';
import { compose, equals, filter, ifElse, isNil, mergeRight, not, path, propEq, sort } from 'ramda';

import { getStatus } from '@/utils/accessors';
import { isJobType } from '@/utils/comparators';
import { computeTaskUnifiedStatus, jobTypeKeys, PAGINATION_THRESHOLD } from '@/utils/constants';
import { extractVersion } from '@/utils/formatters';

const isSubmissionTime = propEq('submitted', 'label');
const isVersion = propEq('version', 'label');
const isDescending = equals('descending');

export const isNotDeleted = compose(not, equals(computeTaskUnifiedStatus.Deleted), getStatus);

const getValue = ifElse(
   (value, accessor) => equals(accessor.label, 'status') && isJobType(jobTypeKeys.remote)(value),
   (value, accessor) => path(accessor.pathRemote, value),
   (value, accessor) => path(accessor.path, value),
);
export const jobSort = ({ accessor, direction }) => {
   return (a, b) => {
      let [firstValue, secondValue] = direction === 'ascending' ? [a, b] : [b, a];

      firstValue = getValue(firstValue, accessor);
      secondValue = getValue(secondValue, accessor);

      if (isVersion(accessor)) {
         const { version: firstVersion, precision: firstPrecision } = firstValue;
         const { version: secondVersion, precision: secondPrecision } = secondValue;
         const firstVersionMarketing = extractVersion(firstVersion.replaceAll('_', '.').slice(1));
         const secondVersionMarketing = extractVersion(secondVersion.replaceAll('_', '.').slice(1));

         if (firstVersionMarketing === secondVersionMarketing) {
            return firstPrecision.localeCompare(secondPrecision);
         }

         return firstVersionMarketing - secondVersionMarketing;
      }

      if (isNil(firstValue)) {
         if (isSubmissionTime(accessor) && isDescending(direction)) {
            return 1;
         }
         return -1;
      }

      if (isNil(secondValue)) {
         if (isSubmissionTime(accessor) && isDescending(direction)) {
            return -1;
         }
         return 1;
      }

      if (accessor.type === 'date') {
         return Date.parse(firstValue) - Date.parse(secondValue);
      }

      if (accessor.type === 'number') {
         return firstValue - secondValue;
      }

      return firstValue.localeCompare(secondValue);
   };
};

export const runningJobs = stream(0);

/**
 * Updates the displayedJobs, pagination and filterOptions when any upstream modifier is updated
 * @param {*} proposedState
 */
export const transformState = (proposedState) => {
   const existingJobs = filter(isNotDeleted, proposedState.workspace.jobs);
   const existingLength = existingJobs.length;
   const sorter = sort(jobSort(proposedState.sorting));
   const sortedJobs = sorter(existingJobs);
   const totalPages = Math.max(1, Math.ceil(sortedJobs.length / PAGINATION_THRESHOLD));
   const currentPage = Math.min(proposedState.page, totalPages);
   const totalDisplayedJobs = sortedJobs.length;

   const paginatedJobs = sortedJobs.slice(PAGINATION_THRESHOLD * (currentPage - 1), PAGINATION_THRESHOLD * currentPage);

   return mergeRight(proposedState, {
      displayedJobs: paginatedJobs,
      page: currentPage,
      totalDisplayedJobs,
      totalPages,
      totalExistingJobs: existingLength,
      runningJobs: runningJobs(),
   });
};
