import { propEq, reduce, reject } from 'ramda';

import services from '@/store/listing/services';

import { dataStatePath, dataStates } from '@/utils/constants';

import { model } from './model';

const kvArrayToObject = reduce((acc, cur) => {
   acc[cur.key] = cur.value;
   return acc;
}, {});

const insertApplication = (mappings, version, precision, type, typeHint, arr) => {
   arr.push({
      marketingVersion: parseFloat(mappings[version]),
      version,
      type,
      typeHint,
      precision: [precision],
   });
   return arr;
};

const updateApplication = (precision, obj) => {
   obj.precision.push(precision);
   return obj;
};
/**
 *
 * @param {{ systemJobTemplates: Object, displayMappings: Object }} param0
 * @returns {{ applications: import('./model').jobTypeApplications, templates: Object, displayMappings: Object }}
 */
const marshalSystemJobTemplates = ({ systemJobTemplates, displayMappings }) =>
   systemJobTemplates.reduce(
      (acc, cur) => {
         acc.templates[cur.jobType] = reject(propEq('null', 'name'), cur.computeTypes);
         if (!acc.applications[cur.jobType]) {
            acc.applications[cur.jobType] = [];
         }

         cur.applicationsSupported.reduce((prev, { version, precision, type, typeHint }) => {
            // limit to star-ccmp app
            if (type !== 'star_ccmp') {
               return acc;
            }
            const obj = prev.find(propEq(version, 'version'));
            if (obj) {
               return updateApplication(precision, obj);
            }
            return insertApplication(acc.mappings, version, precision, type, typeHint, prev);
         }, acc.applications[cur.jobType]);
         return acc;
      },
      { templates: {}, applications: {}, mappings: kvArrayToObject(displayMappings), systemJobTemplates },
   );

export const actions = (present) => {
   return {
      getSystemJobTemplates: (client) => {
         present({ op: 'replace', value: dataStates.loading, path: dataStatePath });

         return services
            .getSystemJobTemplates(client)
            .then(marshalSystemJobTemplates)
            .then(({ templates, applications, mappings }) =>
               present([
                  {
                     op: 'replace',
                     value: mappings,
                     path: '/mappings',
                  },
                  { op: 'replace', value: templates, path: '/templates' },
                  { op: 'replace', value: applications, path: '/applications' },
                  { op: 'replace', value: dataStates.ready, path: dataStatePath },
               ]),
            )
            .catch(() => {
               present({ op: 'replace', value: dataStates.error, path: dataStatePath });
            });
      },
      clear: () => present({ op: 'replace', value: model, path: '' }),
   };
};
