import { gql } from '@urql/core';

export default gql`
   query Job($id: ID!) {
      job(id: $id) {
         tasks {
            name
            inputFileRepository {
               id
            }
            outputFileRepository {
               id
            }
         }
         jobDefinition {
            computeType {
               name
            }
         }
      }
   }
`;
